* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
.app {
	background-color: #fff;
}
.main-container {
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
	padding: 0;
	min-height: calc(100vh - 61px);
	/* background-color: #ddecf8; */
	border-radius: 32px 32px 0px 0px;
}
.navbar {
	background-color: transparent;
	width: 100%;
	padding: 0px;
	width: 100%;
	z-index: 2;
	transition: background-color 0.3s ease-out;
}
.navbar-logo {
	display: flex;
}
.navbar-logo img {
	max-height: 70px;
}
.navbar.fill {
	background-color: #fff;
	box-shadow: 0 6px 11px 5px rgba(0, 0, 0, 0.05);
}
.navbar > div {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 1rem;
}
.navbar svg {
	height: auto;
	width: 105px;
}
.card {
	border-radius: 4px !important;
	background: #fff;
	box-shadow: none;
}
.form-page {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	flex-wrap: wrap;
	margin-bottom: 2rem;
	flex-direction: row;
	padding: 0 1rem;
}
.form-container {
	flex: 2;
	padding-top: 0px;
}
.form-group {
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 24px;
	padding: 24px 16px;
	box-shadow: 0px 1px 4px 0px rgba(4, 0, 71, 0.08),
		0px 16px 32px 0px rgba(4, 0, 71, 0.08);
}
.ai-avatar {
	display: flex;
	align-items: center;
	position: relative;
}
.ai-avatar span {
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	padding-left: 8px;
	white-space: nowrap;
}
label {
	margin-top: 0.7rem;
	color: #3b4b66;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

input,
.select {
	height: 42px;
	/* border: none !important; */
}
textarea {
	height: 140px;
}
input::placeholder,
textarea::placeholder,
.select__placeholder {
	color: #8494b2 !important;
	font-family: Poppins !important;
	font-size: 14px !important;
}
.select__placeholder {
	padding-top: 2px;
}
input,
.select,
textarea {
	color: #15253f !important;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	padding: 14px;
	font-family: Poppins !important;
	border: 1px solid #90a0b7 !important;
}
button {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all 0.2s ease;
}
.submit-btn {
	margin-top: 2rem;
}
.submit-btn {
	padding: 12px 30px;
	/* border: 1px solid #fba91a;
	background: #fba91a;
	color: #fff; */
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
	line-height: 16px;
	text-align: center;
	border-radius: 100px;
	margin-inline: auto;
	width: 100%;
	font-family: Poppins;
	opacity: 1;
}
.submit-btn.disabled {
	cursor: not-allowed;
}
.submit-btn:hover,
.signup-btn:hover {
	opacity: 0.7;
}
.feedback-container {
	flex: 4;
	padding-top: 65px;
	display: none;
}
.form {
	display: block;
}
.feedback-container.show {
	display: block;
	background: white;
	border-radius: 24px;
	box-shadow: 0px 1px 4px 0px rgba(4, 0, 71, 0.08),
		0px 16px 32px 0px rgba(4, 0, 71, 0.08);
}
.form.hide {
	display: none;
}
.example-questions {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}
.example-question {
	color: #000;
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	border-radius: 16px;
	border: 1px solid;
	padding: 6px 12px;
	width: fit-content;
	cursor: pointer;
	opacity: 1;
}
.example-questions.first > :nth-child(1) {
    margin-right: 50px;
}
.example-question:hover {
	opacity: 0.9;
	box-shadow: 0px 1px 4px 0px rgb(19 19 19 / 43%), 0px 16px 32px 0px rgb(0 0 0 / 2%);
}
.feedback {
	/* padding: 1.5rem; */
	/* margin-top: 46px; */
	padding: 1.5rem;
	display: none;
}
.feedback.hide {
	padding: 0;
	display: block;
}
.feedback-documents {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	flex-wrap: wrap;
	border-bottom: none;
	padding: 0 1.5rem 0.3rem;
	flex-direction: column;
}
.feedback-documents.none {
	border-bottom: none;
	padding: 0;
}
.feedback-header {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 0.5rem;
}
.feedback-header span {
	color: #000;
	font-size: 11px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.045px;
}
.feedback-file {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 8px;
}

.feedback-file span {
	color: #000;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.039px;
}
.feedback-file span.file-title {
	font-weight: 600;
	display: flex;
	align-items: center;
}
.file-title {
	cursor: pointer;
}
.file-title svg {
	margin-right: 6px;
}
.react-dropdown-select-content span {
	color: #15253f;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	padding: 14px;
}

input:focus,
input:focus-visible,
.react-dropdown-select.select.card:focus,
.react-dropdown-select.select.card:focus-within,
textarea:focus,
textarea:focus-visible {
	outline: 0.5px solid rgb(144 160 183) !important;
	box-shadow: none !important;
}
.basic-single {
	border: 1px solid #90a0b7 !important;
	border-radius: 4px;
}
.select__value-container {
	padding: 0px 14px !important;
	margin: 0 !important;
	height: 42px;
	border-color: transparent !important;
}
.basic-single:focus,
.basic-single:focus-visible {
	outline: 0.5px solid rgb(144 160 183) !important;
	box-shadow: none !important;
}
.select__control {
	border: none !important;
	border-radius: 4px !important;
}
.select__indicators {
	display: none !important;
}
.select__input-container {
	height: 42px;
	margin: 0 !important;
}
.select__input-container input:focus,
.select__input-container input:focus-visible {
	outline: none !important;
	box-shadow: none !important;
}
.select__input-container input, .select__single-value {
	color: #15253f;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 22px !important;
	padding: 10px 14px 10px 2px;
	font-family: Poppins !important;
	height: auto;
	border: none !important;
}
.select__option {
	font-size: 14px !important;
	cursor: pointer !important;
}
.select__single-value {
	margin-left: 0 !important;
}
.select__menu {
	border-radius: 4px !important;
	outline: 1px solid #90a0b7 !important;
}
.react-dropdown-select-input {
	display: none;
}
.react-dropdown-select-dropdown {
	border-radius: 4px !important;
	box-shadow: none !important;
}
.feedback-instructions {
	padding: 0.5rem 1.5rem;
	font-size: 12px;
}
.feedback-instructions.none {
	padding: 0;
}
/* .feedback-instructions p {
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.039px;
} */
.feedback-documents__col {
	flex: 1;
}
.modal {
	display: none;
}
.modal.visible {
	background: #000000c2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: block;
	display: grid;
	place-items: center;
}
.modal-content {
	background: #fff;
	border-radius: 10px;
	padding: 2rem;
	position: relative;
}
.no-results {
	padding: 68px 12px 64px;
	display: flex;
	gap: 24px;
	margin-top: 8px;
}
.no-results__icon {
	width: 44px;
	height: 44px;
	background-color: #ddecf8;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}
.no-results__text-title {
	color: #000;
	font-size: 16px;
	font-weight: 700;
	line-height: 26px;
}
.no-results__text-subtitle {
	color: #3b4b66;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	padding: 4px 0px 24px;
}
.no-results__email {
	display: flex;
	align-items: center;
	gap: 20px;
}
.no-results__email a {
	color: #5a55ab;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 700;
	line-height: 26px;
}
.feedback-top {
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5rem 0.5rem;
	border-bottom: 1px solid #f6f8fc;
	gap: 1rem;
	display: flex;
	margin-bottom: 0.5rem;
}
.document-title {
	color: #000;
	font-size: 12px;
	font-weight: 700;
	line-height: 22px;
	max-width: 398px;
}
.document-title {
	color: #000;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	max-width: 398px;
}
.feedback-top__question {
	align-items: center;
	gap: 8px;
	display: none;
}
.feedback-top__icon {
	width: 24px;
	height: 24px;
	background-color: #ddecf8;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px;
}
.feedback-top__text {
	color: #5a55ab;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	max-width: 251px;
}
.user-feedback {
	display: none;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	gap: 1rem;
	padding: 16px;
	max-width: 450px;
	margin: 32px auto;
	/* box-shadow: 0px 16px 32px 0px rgba(4, 0, 71, 0.08),
		0px 1px 4px 0px rgba(4, 0, 71, 0.08); */
	position: sticky;
	bottom: 38px;
	border: 1px solid;
	background-color: #fff;
}
.user-feedback__mobile {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	gap: 1rem;
	padding: 16px;
	max-width: 450px;
	/* margin: 32px auto; */
	/* box-shadow: 0px 1px 4px 0px rgba(4, 0, 71, 0.08),
		0px 16px 32px 0px rgba(4, 0, 71, 0.08); */
	border: 1px solid;
}
.user-feedback__text {
	color: #000;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}
.user-feedback__buttons {
	display: flex;
	gap: 8px;
}
.user-feedback__button {
	border: 2px solid #5a55ab;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
.user-feedback__button svg path {
	/* fill: #5a55ab; */
}
.user-feedback__button:hover {
	/* background-color: #5a55ab; */
}
.user-feedback__button:hover svg path {
	/* fill: #fff; */
}
@media (max-width: 1000px) {
	.form-page {
		flex-direction: column;
	}
	/* .navbar {
		padding: 22px 0 44px;
	} */
	.feedback-container {
		padding-top: 0px;
	}
	.feedback {
		margin-top: 22px;
	}
}
.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms;
}
#bars {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	position: relative;
	bottom: 80px;
}

.bar {
	background: #52467b;
	bottom: 1px;
	height: 2px;
	width: 2px;
	margin: 0px 4px;
	border-radius: 5px;
	animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
	0% {
		opacity: 0.35;
		height: 2px;
	}
	100% {
		opacity: 1;
		height: 20px;
	}
}

.bar:nth-child(1) {
	left: 1px;
	animation-duration: 474ms;
}
.bar:nth-child(2) {
	left: 15px;
	animation-duration: 433ms;
}
.bar:nth-child(3) {
	left: 29px;
	animation-duration: 407ms;
}
.bar:nth-child(4) {
	left: 43px;
	animation-duration: 458ms;
}
.bar:nth-child(5) {
	left: 57px;
	animation-duration: 400ms;
}
.bar:nth-child(6) {
	left: 71px;
	animation-duration: 427ms;
}
.bar:nth-child(7) {
	left: 85px;
	animation-duration: 441ms;
}
.bar:nth-child(8) {
	left: 99px;
	animation-duration: 419ms;
}
.bar:nth-child(9) {
	left: 113px;
	animation-duration: 487ms;
}
.bar:nth-child(10) {
	left: 127px;
	animation-duration: 442ms;
}
.microphone-background {
	width: 100%;
	height: 52px;
}
.microphone {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0189cf;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	cursor: pointer;
	margin-left: auto;
	margin-top: 4px;
	opacity: 1;
	position: absolute;
	right: 0;
}
.microphone.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.microphone:hover {
	opacity: 0.8;
}
.microphone svg {
	width: 16px;
	height: 16px;
}
.stop-recording {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0189cf;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	cursor: pointer;
	position: relative;
	z-index: 1;
	opacity: 1;
}
.stop-recording span {
	width: 16px;
	height: 16px;
	border: 2px solid #fff;
	border-radius: 3px;
}
.stop-recording:hover {
	/* background-color: #31a2db; */
	opacity: 0.8;
}
.stop-recording__container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-inline: auto;
	bottom: 14px;
	height: 36px;
}
.stop-recording__background {
	background-color: #aad6dd;
	width: 36px;
	height: 36px;
	animation: pulsate 700ms linear infinite alternate;
	border-radius: 50%;
	position: absolute;
	opacity: 0.5;
}
@keyframes pulsate {
	0% {
		width: 36px;
		height: 36px;
		opacity: 0.5;
	}
	25% {
		width: 44px;
		height: 44px;
		opacity: 0.4;
	}
	50% {
		width: 40px;
		height: 44px;
		opacity: 0.5;
	}

	100% {
		width: 52px;
		height: 52px;
		opacity: 0.2;
	}
}

.progress-bar__background {
	background-color: #e5eaf2;
	border-radius: 10px;
	height: 4px;
	width: 100%;
	margin: 10px 0px 14px 0px;
}
.progress-bar {
	height: 4px;
	background-color: #31a2db;
	transition: width 0.4s ease-in-out;
	border-radius: 10px;
}

.progress-text {
	color: #556685;
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	text-align: center;
}

.status-text {
	color: #8494b2;
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}
.input-container {
	position: relative;
}
textarea {
	min-width: 100%;
}
.react-dropdown-select-item-disabled {
	background: transparent !important;
	color: #15253f !important;
}
.spnr {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	border: 5px solid transparent;
	animation: spin 1s linear infinite;

	background: linear-gradient(white, white),
		conic-gradient(from 0.15turn, white, #5456a2);
	background-origin: border-box;
	background-clip: content-box, border-box;
	position: relative;
	bottom: 80px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.spinnerWrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.spinner {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #45a2d9;

	border-radius: 100%;
	animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
.ai-avatar__container {
	display: none;
}
.ai-avatar__container--mobile {
	display: flex;
	padding: 2rem 0 2rem;
}
.ai-avatar__container--mobile.hide {
	display: none;
}
.ai-avatar__mobile {
	margin-inline: auto;
}
.ai-avatar__mobile div,
.ai-avatar__name {
	/* padding-left: 60px; */
	color: #90a0b7;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	position: relative;
	top: 6px;
}
.ai-avatar__name {
	position: absolute;
	bottom: 24px;
	top: unset;
}
.navbar-mobile {
	display: none;
	/* min-height: 25px; */
}
.divider {
	display: inline;
	width: 100%;
	height: 1px;
	background-color: #efeef7;
	margin: 1.5rem 0 4px;
}
.question {
	padding: 24px 16px;
	background: white;
	border-radius: 24px;
	box-shadow: 0px 1px 4px 0px rgba(4, 0, 71, 0.08),
		0px 16px 32px 0px rgba(4, 0, 71, 0.08);
	color: #3b4b66;
	font-family: Poppins;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	display: flex;
	margin: 1rem 0;
}
.results {
	display: none;
}
.back-btn {
	display: flex;
	margin-left: auto;
}
.back-btn button {
	display: inline-flex;
	padding: 16px 24px;
	align-items: center;
	gap: 10px;
	border-radius: 24px;
	border: 1px solid #5a55ab;
	color: #000;
	background: #fff;
	font-size: 14px;
	font-family: Poppins;
	box-shadow: 0px 1px 4px 0px rgba(4, 0, 71, 0.1),
		0px 4px 8px 0px rgba(4, 0, 71, 0.06);
	background-color: #fff;
}
.back-btn svg {
	width: 24px;
	height: 24px;
}
.back-btn svg path {
	/* fill: #5a55ab; */
}
.back-btn button:hover {
	/* background: #5a55ab;
	color: #fff; */
}
.back-btn button:hover svg path {
	/* fill: #fff; */
}
.feedback_icon {
	display: flex;
	align-items: center;
}
.feedback-warning {
	color: black;
	margin-bottom: 10px;
	background-color: rgb(255, 236, 208);
	padding: 8px 8px;
	display: flex;
	font-size: 11px;
	border-radius: 8px;
}
.progress-desktop {
	display: none;
}
.progress-mobile {
	display: block;
}
.step {
	padding-bottom: 0px;
}
.step.first {
	padding-top: 10px;
}
.steps-line {
	padding: 6px 0;
}
.error-mobile {
	display: block;
	background: white;
	border-radius: 24px;
	box-shadow: 0px 1px 4px 0px rgba(4, 0, 71, 0.08),
		0px 16px 32px 0px rgba(4, 0, 71, 0.08);
	margin-bottom: 12px;
}
.options-checkbox {
	display: block;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-left: 0;
}
.options-checkbox.mobile {
	display: flex;
}
.options-checkbox.desktop {
	display: none;
}
label.options-checkbox {
	margin-top: 1rem;
	line-height: 26px;
}
/* Hide the browser's default checkbox */
.options-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid grey;
	border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.options-checkbox:hover input ~ .checkmark {
	border: 1px solid grey;
	border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
/* .options-checkbox input:checked ~ .checkmark {
	background-color: #2196f3;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.options-checkbox input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.options-checkbox .checkmark:after {
	left: 7px;
	top: 3px;
	width: 6px;
	height: 10px;
	border: solid rgb(106, 95, 95);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.dropdown-input {
	position: relative;
}

.dropdown-input input {
	width: 100%;
	box-sizing: border-box;
	border-radius: 4px;
}

.dropdown-list {
	position: absolute;
	top: 48px;
	left: 0;
	width: 100%;
	border: 1px solid #ccc;
	background-color: #fff;
	z-index: 1000;
	max-height: 150px;
	overflow-y: auto;
	list-style-type: none;
	margin: 0;
	padding: 0;
	border-radius: 4px;
}

.dropdown-list li {
	padding: 5px 10px;
	cursor: pointer;
	border-bottom: 1px solid #fff;
	color: #15253f;
	font-size: 14px;
	font-family: Poppins;
}

.dropdown-list li:hover {
	background-color: rgba(0, 116, 217, 0.1);
}
.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgb(255, 152, 0); /* Red background */
  color: #000;
  padding: 10px 16px;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
	display: flex;
	align-items: center;
  gap: 8px;
	font-size: 14px;
	border: 1px solid rgb(255, 152, 0);
	border-radius: 4px;
}
.alert svg path {
	fill: #663c00;
}
.alert button {
	background: none;
	border: none;
	color: #663c00;
	cursor: pointer;
	position: relative;
	bottom: 7px;
	left: 8px;
	padding: 3px;
}
.alert button svg {
	width: 10px;
	height: 10px;
}

.alert.show {
  opacity: 1;
}
@media (min-width: 460px) {
	.main-container {
		min-height: auto;
		padding: 0 2rem;
		/* background-color: #ecf6ff; */
	}
	.ai-avatar__container {
		display: flex;
	}
	.ai-avatar__container--mobile {
		display: none;
	}
	.navbar-mobile {
		display: none;
	}
	.form-group {
		background: transparent;
		border-radius: 0px;
		padding: 0px;
		box-shadow: none;
	}
	.submit-btn {
		width: auto;
	}
	.divider {
		display: none;
	}
	label {
		margin-top: 1rem;
		margin-bottom: 2px;
	}
	input,
	.select {
		height: 42px;
	}
	.form-page {
		padding: 0;
	}
	.form-container {
		padding-top: 40px;
	}
	.feedback-container {
		display: block;
	}
	.feedback-container.show {
		background: transparent;
		border-radius: 0px;
		box-shadow: none;
	}
	.question {
		display: none;
	}
	.form.hide {
		display: block;
	}
	.results {
		display: flex;
	}
	.feedback-top {
		justify-content: space-between;
		align-items: center;
		padding: 1.5rem 1.5rem 1rem;
		border-bottom: 1px solid #f6f8fc;
		gap: 1rem;
		margin-bottom: 0;
	}
	.feedback-top__question {
		display: flex;
	}
	.document-title {
		font-size: 14px;
	}
	.feedback-documents {
		border-bottom: 1px solid #f6f8fc;
		flex-direction: row;
		gap: 1rem;
		padding: 1rem 1.5rem 1.5rem;
	}
	.user-feedback {
		display: flex;
	}
	.user-feedback__mobile {
		display: none;
	}
	.back-btn {
		display: none;
	}
	.feedback-file {
		margin-bottom: 12px;
	}

	.feedback-file span {
		font-size: 14px;
	}
	.feedback-header {
		margin-bottom: 1rem;
	}
	.feedback-header span {
		font-size: 12px;
	}
	.feedback-instructions {
		padding: 0.5rem 1.5rem;
		font-size: 14px;
	}
	.feedback-warning {
		padding: 16px 24px;
		font-size: 12px;
	}
	.feedback {
		display: block;
	}
	.progress-desktop {
		display: block;
	}
	.progress-mobile {
		display: none;
	}
	.form-page {
		gap: 3rem;
	}
	.step {
		padding-bottom: 10px;
	}
	.steps-line {
		padding: 6px 0;
	}
	.error-mobile {
		display: none;
	}
	.no-results {
		padding: 80px 40px 86px;
		display: flex;
		gap: 24px;
		margin-top: 8px;
	}
	.navbar {
		background-color: transparent;
		width: 100%;
		padding: 1px 0 3px;
		width: 100%;
		z-index: 2;
		transition: background-color 0.3s ease-out;
		border-bottom: 1px solid #d9e1ee;
	}
	.options-checkbox.mobile {
		display: none;
	}
	.options-checkbox.desktop {
		display: flex;
	}
	.options-checkbox {
		margin-left: auto;
	}
	label.options-checkbox {
		margin-top: 0;
	}
}
@media (min-width: 1000px) {
	.form-container {
		padding-top: 70px;
	}
}
